import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.luchow,
    earningToken: serializedTokens.luchow,
    contractAddress: {
      80001: '0xd3f8ae52320676335d4be44e9ee4f3ac4510e1db',  // masterchef address for pid=0 pool
      137: '0xd3f8ae52320676335d4be44e9ee4f3ac4510e1db',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '100',
    sortOrder: 1,
    isFinished: false,
  },
]

export default pools
