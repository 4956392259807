import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  luchow: new Token(
    MAINNET,
    '0xc4bb7277a74678f053259cb1f96140347efbfd46',
    18,
    'LUCHOW',
    'LuchowSwap Token',
    'https://lunachow.com/',
  ),
  busd: new Token(
    MAINNET,
    '0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  usdt: new Token(
    MAINNET,
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  wmatic: new Token(
    MAINNET,
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    18,
    'WMATIC',
    'Polygon Token',
    'https://polygonscan.com/en/',
  ),
  weth: new Token(
    MAINNET,
    '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    18,
    'WETH',
    'Ethereum Token',
    'https://ethereum.org/en/',
  ),
  usdc: new Token(
    MAINNET,
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    6,
    'USDC',
    'Ethereum USD Coin',
    'https://www.centre.io/usdc',
  ),
  dai: new Token(
    TESTNET,
    '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    18,
    'DAI',
    'Ethereum DAI Coin',
    'https://www.centre.io/dai',
  )
}

export const testnetTokens = {
  luchow: new Token(
    TESTNET,
    '0xde04E60e54D54B4a13DE8f6BeA35A41cBa518a1a',
    18,
    'LUCHOW',
    'LuchowSwap Token',
    'https://lunachow.com/',
  ),
  busd: new Token(
    TESTNET,
    '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  usdt: new Token(
    TESTNET,
    '0x3B00Ef435fA4FcFF5C209a37d1f3dcff37c705aD',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  wmatic: new Token(
    MAINNET,
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    18,
    'WMATIC',
    'Polygon Token',
    'https://polygonscan.com/en/',
  ),
  weth: new Token(
    TESTNET,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Ethereum Token',
    'https://ethereum.org/en/',
  ),
  usdc: new Token(
    TESTNET,
    '0xeb8f08a975Ab53E34D8a0330E0D34de942C95926',
    6,
    'USDC',
    'Ethereum USD Coin',
    'https://www.centre.io/usdc',
  ),
  dai: new Token(
    TESTNET,
    '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735',
    18,
    'DAI',
    'Ethereum DAI Coin',
    'https://www.centre.io/usdc',
  )
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
