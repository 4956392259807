export default {
  masterChef: {
    80001: '',
    137: '0xd3f8ae52320676335d4be44e9ee4f3ac4510e1db',
  },
  sousChef: {
    80001: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    137: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    80001: '',
    137: '0xb4796a5aC0CD46e4cA28432c9147780A138f6726',
  },
  multiCall: {
    137: '0x11ce4b23bd875d7f5c6a31084f55fde1e9a87507',
    80001: '0x08411add0b5aa8ee47563b146743c13b3556c9cc',
  },
  pancakeProfile: {
    137: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    80001: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    137: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    80001: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    137: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    80001: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    137: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    80001: '',
  },
  pointCenterIfo: {
    137: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    80001: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    137: '0xFee8A195570a18461146F401d6033f5ab3380849',
    80001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    137: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    80001: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    137: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    80001: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    137: '0xaf2fafed9b4b5a5d091525c9bc0b099e0deafafe',
    80001: '',
  },
  predictions: {
    137: '0xaf2fafed9b4b5a5d091525c9bc0b099e0deafafe',
    80001: '',
  },
  chainlinkOracle: {
    137: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    80001: '',
  },
  bunnySpecialCakeVault: {
    137: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    80001: '',
  },
  bunnySpecialPrediction: {
    137: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    80001: '',
  },
  bunnySpecialLottery: {
    137: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    80001: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    137: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    80001: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    137: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    80001: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    137: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    80001: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    137: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    80001: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    137: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    80001: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
}
