import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'LUCHOW',
    lpAddresses: {
      80001: '',
      137: '0xc4bb7277a74678f053259cb1f96140347efbfd46',
    },
    token: serializedTokens.luchow,
    quoteToken: serializedTokens.weth,
  },
  {
    pid: 1,
    lpSymbol: 'LUCHOW-WMATIC LP',
    lpAddresses: {
      80001: '',
      137: '0x39efdf3380883ff53507224cad2f6a82feaa4de7',
    },
    token: serializedTokens.luchow,
    quoteToken: serializedTokens.wmatic,
  },
  {
    pid: 2,
    lpSymbol: 'LUCHOW-BUSD LP',
    lpAddresses: {
      80001: '',
      137: '0x42837cd90725e516b1d52b1854234349b78c0027',
    },
    token: serializedTokens.luchow,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'LUCHOW-USDC LP',
    lpAddresses: {
      80001: '',
      137: '0x6943df114f3b9fa566558a12bce850105bbded66',
    },
    token: serializedTokens.luchow,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 4,
    lpSymbol: 'WMATIC-BUSD LP',
    lpAddresses: {
      80001: '',
      137: '0x65b7ba2145f01874e580df405e224cda61aa3244',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wmatic,
  },
  {
    pid: 5,
    lpSymbol: 'WMATIC-USDC LP',
    lpAddresses: {
      80001: '',
      137: '0x6be6274b83fa97ab39c14d0de42ec006c11d3cab',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wmatic,
  },
]

export default farms
